import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay])

export default function sliders() {
	;(function ($) {
		$(window).on('load', function () {
			// get all sliders, we need to loop them due to different settings + nav
			var swipers = document.querySelectorAll('.swiper-gallery')
			swipers.forEach(function (el, index) {
				var closestSection = el.closest('section')
				var controls = closestSection.querySelector('.control')

				// slider settings
				var options = {
					speed: 600,
					loop: true,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					pagination: {
						el: '.swiper-pagination',
						clickable: true,
					},
					thumbs: {},
					spaceBetween: 68,
					slidesPerView: 1,
					centeredSlides: true,
					breakpoints: {
						640: {
							slidesPerView: 1,
						},
						992: {
							slidesPerView: 1,
						},
					},
				}

				// init slider
				new Swiper(el, options)
			})

			// Process Swiper
			let processSwiper = document.querySelector(
				'.swiper-gallery.process'
			)

			// Process Slider Settings
			let processOptions = {
				speed: 800,
				loop: true,
				autoplay: {
					delay: 3000,
					disableOnInteraction: true,
				},
				disabled: true,
				thumbs: {},
				spaceBetween: 68,
				slidesPerView: 1,
				centeredSlides: true,
			}

			// Testimonial Swiper
			let testimonialSwiper = document.querySelector(
				'.swiper-gallery.testimonial'
			)

			// Testimonial Slider Settings
			let testimonialOptions = {
				speed: 7000,
				loop: true,
				spaceBetween: 68,
				slidesPerView: 1,
				freeMode: true,
				freeModeMomentum: false,
				grabCursor: true,
				autoplay: {
					delay: 1,
					disableOnInteraction: false,
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				thumbs: {},
			}

			// init slider
			new Swiper(processSwiper, processOptions)
			new Swiper(testimonialSwiper, testimonialOptions)
		})
	})(jQuery)
}
