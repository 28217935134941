import { exit } from 'process'

export default function popUp() {
	;(function ($) {
		// Exit Popup
		function onMouseOut(event) {
			let exitBtnOffer = document.querySelector('.exit-btn-offer')
			let exitBtnQtn = document.querySelector('.exit-btn-qtn')

			if (
				event.clientY < 50 &&
				event.relatedTarget == null &&
				event.target.nodeName.toLowerCase() !== 'select'
			) {
				// Remove this event listener
				document.removeEventListener('mouseout', onMouseOut)

				// Show the popup
				if (exitBtnOffer) {
					exitBtnOffer.click()
				}

				let activeLi = document.querySelectorAll(
					'#progressbar li.active'
				)

				if (exitBtnQtn) {
					exitBtnQtn.click()
				}
			}
		}

		document.addEventListener('mouseout', onMouseOut)
	})(jQuery)
}
