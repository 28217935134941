import { doc } from 'prettier'

export default function multistep() {
	;(function ($) {
		if (
			$('body.questionnaire-page').length ||
			$('#questionaire-form').length
		) {
			// Check which CRM is used
			var form = ''
			if ($('form .infusion-field').length) {
				form = $('form .infusion-field')
			} else if ($('form fieldset').length) {
				form = $('form fieldset')
			} else if ($('form .form-group')) {
				form = $('form .form-group')
			} else if ($('form ._form_element').lemgth) {
				form = $('form ._form_element')
			}

			/* Make the outer wrapper the same height as the tallest field. */
			// Get an array of all element heights
			function setFormHeight() {
				$('form').css('height', '') // reset

				var elementHeights = form
					.map(function () {
						return $(this).innerHeight()
					})
					.get()

				var maxHeight = Math.max.apply(null, elementHeights)
				// Set each height to the max height
				$('form').height(maxHeight)
			}

			var resizeTimerForms
			$(window).on('resize load', function () {
				clearTimeout(resizeTimerForms)
				resizeTimerForms = setTimeout(function () {
					setFormHeight()
				})
			})

			$(window).on('load', function () {
				// multistep form code
				var current_fs, next_fs, previous_fs //fieldsets
				var left, opacity, scale //fieldset properties which we will animate
				var textInput,
					checkboxChecked,
					radioChecked,
					rangeSet,
					toggleChecked,
					invalidOverride

				// With radio buttons, no need of next button
				$('form input[type=radio]').click(function () {
					//Find nearest "next" button and activate it
					$(this).closest(form).find('input.next').click()
				})

				$('.next').click(function () {
					textInput = false
					checkboxChecked = false
					radioChecked = false
					rangeSet = false
					toggleChecked = false
					invalidOverride = false

					current_fs = $(this).parent()
					next_fs = $(this).parent().next()

					/* validation - if fails display an error message. */
					checkboxChecked = $(this)
						.parent()
						.find('input[type=checkbox]')
						.is(':checked')

					radioChecked = $(this)
						.parent()
						.find('input[type=radio]')
						.is(':checked')

					rangeSet = $(this).parent().find('input[type=range]').val()

					toggleChecked = $(this)
						.parent()
						.find('.toggle-options input[type=checkbox]')
						.is(':checked')

					if (!$(this).parent().find('[required]').length) {
						invalidOverride = true
					}

					if (
						$(this).parent().find('input[type=text]').val() ||
						$(this).parent().find('input[type=email]').val() ||
						$(this).parent().find('input[type=tel]').val() ||
						$(this).parent().find('textarea').val() ||
						$(this).parent().find('select').val()
					) {
						textInput = true
					}

					if (
						checkboxChecked == false &&
						radioChecked == false &&
						textInput == false &&
						!rangeSet &&
						!invalidOverride
					) {
						Swal.fire({
							title: 'Something went wrong!',
							text: 'Please provide an answer to this question before moving on...',
							icon: 'error',
							showConfirmButton: false,
							timer: 5000,
						})
					} else {
						//activate next step on progressbar using the index of next_fs
						$('#progressbar li')
							.eq($(form).index(next_fs))
							.addClass('active')
						next_fs.css('visibility', 'visible')

						let stepsDone = document.querySelectorAll(
							'#progressbar li.active'
						)

						stepsDone.forEach((el, index) => {
							if (stepsDone.length - 1 === index) {
								el.querySelector('img').classList.remove(
									'd-none'
								)
							} else {
								el.querySelector('img').classList.add('d-none')
							}
						})

						// let activelist = 	[...document.querySelectorAll('#progressbar li.active')]

						// activelist[activelist.length - 1]

						//hide the current fieldset with style
						current_fs.animate(
							{ opacity: 0 },
							{
								step: function (now, mx) {
									//1. scale current_fs down to 80%
									scale = 1 - (1 - now) * 0.2
									//2. bring next_fs from the right(50%)
									left = now * 50 + '%'
									//3. increase opacity of next_fs to 1 as it moves in
									opacity = 1 - now

									current_fs.css({
										transform: 'scale(' + scale + ')',
										'pointer-events': 'none',
									})
									next_fs.css({
										left: left,
										opacity: opacity,
										'pointer-events': 'all',
									})
								},
								duration: 1000,

								//this comes from the custom easing plugin
								easing: 'easeInOutBack',
							}
						)

						sendFirstStep(current_fs);
					}
				})

				$('.previous').click(function () {
					current_fs = $(this).parent()
					previous_fs = $(this).parent().prev()

					//de-activate current step on progressbar & show previous step
					$('#progressbar li')
						.eq($(form).index(current_fs))
						.removeClass('active')
					next_fs.css('visibility', 'visible')

					//hide the current fieldset with style
					current_fs.animate(
						{ opacity: 0 },
						{
							step: function (now, mx) {
								//1. scale previous_fs from 80% to 100%
								scale = 0.8 + (1 - now) * 0.2
								//2. take current_fs to the right(50%) - from 0%
								left = (1 - now) * 50 + '%'
								//3. increase opacity of previous_fs to 1 as it moves in
								opacity = 1 - now

								current_fs.css({
									left: left,
									'pointer-events': 'none',
								})
								previous_fs.css({
									transform: 'scale(' + scale + ')',
									opacity: opacity,
									'pointer-events': 'all',
								})
							},
							duration: 1000,

							//this comes from the custom easing plugin
							easing: 'easeInOutBack',
						}
					)
				})

				// stop enter key submitting form
				document.addEventListener('keydown', function (e) {
					if (e.which == '13') {
						e.preventDefault()
					}
				})

				$('.submit').click(function () {
					return false
				})
			})

			// Dynamic Naming
			if (document.querySelector('body.questionnaire-page')) {
				document
					.querySelector("input[type='button'].next.name-container")
					.addEventListener('click', () => {
						let userName = document.querySelector(
							"input[type='text'].form-name"
						).value

						document
							.querySelectorAll('.user-name')
							.forEach((el) => {
								el.innerText = userName
							})
					})
			}

			// Dynamic Button Text
			if (document.querySelector('body.questionnaire-page')) {
				let toggles = document.querySelectorAll(
					".two-btns input[type='checkbox']"
				)
				let inputOther = document.querySelector('.input-other')

				toggles.forEach((toggle) => {
					toggle.addEventListener('change', (e) => {
						let NextBtn = toggle
							.closest('fieldset')
							.querySelector('input.next')

						let DupeBtn = toggle
							.closest('fieldset')
							.querySelector('input.next-dupe')

						DupeBtn.classList.remove('d-none')

						DupeBtn.addEventListener('click', () => {
							NextBtn.click()
						})
					})
				})

				inputOther.addEventListener('click', () => {
					let NextBtn = inputOther
						.closest('fieldset')
						.querySelector('input.next')

					let DupeBtn = inputOther
						.closest('fieldset')
						.querySelector('input.next-dupe')

					DupeBtn.classList.remove('d-none')

					DupeBtn.addEventListener('click', () => {
						NextBtn.click()
					})
				})
			}
		}


		function sendFirstStep(currentFields) {
			// Check if the current field contains the phone number (which is the last field in order to send)
			var phoneInput = currentFields.find('input[name="phone"]');
			// If the phone number field exists, then send the info
			if(phoneInput.length == 1) {
				// Get the values of the name, phone and email
				var $inputs = $('#questionnaire-form :input');

				var formFields = {}
				$.each(
					$('#questionnaire-form, #questionnaire-mn-form').serializeArray(),
					function (i, field) {
						formFields[field.name] = field.value
					}
				)
				// Remove the default entries from the form
				delete formFields['field[4]'];
				delete formFields['field[7]'];
				delete formFields['field[9]'];
				delete formFields.planet;

				// Add the action url to our form fields
				formFields['action'] = $('#questionnaire-form, #questionnaire-mn-form').attr('action');

				// Send those values to our function
				console.log('values: ', formFields)
				$.ajax({
					url: '/.netlify/functions/first-step-submit',
					data: formFields,
					success: function (result) {
						console.log(result);
					},
				})
				// Tracking event for first step
				dataLayer.push({ event: 'gtm.questionnaireStepOne' })
			}
		}

	})(jQuery)
}
