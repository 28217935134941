// import './vendor/jquery.validate.min.js'
import contentLoaded from './vendor/contentloaded.min.js'
import './vendor/jquery.viewportchecker.min.js'
import './vendor/jquery.easing.min.js'
import './vendor/jquery-ui.js'
import './vendor/lottie.js'
import { rangeSliderInit, runRangeSlider } from './vendor/rangeslider.js'
import accordion from './accordion.js'
import images from './images.js'
import multistep from './multi-step-form.js'
import phoneConcat from './phone-concatination.js'
import polyfill from './polyfills.js'
import sliders from './sliders.js'
import smoothScroll from './smooth-scroll.js'
import stickyHeader from './sticky-header.js'
import timer from './timer.js'
import videos from './video-embeds.js'
import viewAnimation from './view-animation.js'
import getCurrentMonth from './month.js'
import kkThankYou from './thank-you.js'
import calendar from './calendar.js'
import rolodex from './rolodex.js'
import popUp from './popup.js'
import initModal from './initModal.js'

contentLoaded()

// easing()
// validate()

window.contentLoaded(window, function (e) {
	rangeSliderInit()
	runRangeSlider()
	polyfill()
	kkThankYou()
	phoneConcat()
	images()
	getCurrentMonth()
	sliders()
	smoothScroll()
	stickyHeader()
	videos()
	viewAnimation()
	getCurrentMonth()
	multistep()
	calendar()
	rolodex()
	popUp()
	initModal()
})
