import { doc } from 'prettier'

export default function rolodex() {
	// Rollo text animation
	const svgs = [
		`<svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.6614 4.53828H16.2485C16.0237 4.53828 15.842 4.71994 15.842 4.94475C15.842 5.16955 16.0237 5.35123 16.2485 5.35123H17.6614C17.8861 5.35123 18.0678 5.16959 18.0678 4.94479L17.6614 4.53828ZM17.6614 4.53828C17.8861 4.53828 18.0678 4.7199 18.0678 4.94475L17.6614 4.53828Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
<path d="M8.82035 0.887941L8.82035 0.88793C8.71995 0.687164 8.80114 0.443275 9.00172 0.342717C9.20321 0.243059 9.44631 0.323736 9.54784 0.524782C9.54789 0.524896 9.54795 0.525009 9.54801 0.525123L10.254 1.93719C10.254 1.93719 10.254 1.93719 10.254 1.9372C10.3544 2.13802 10.2731 2.38197 10.0726 2.48248C9.86957 2.58307 9.62713 2.50073 9.52677 2.30072C9.52674 2.30067 9.52672 2.30062 9.52669 2.30057L8.82035 0.887941Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
<path d="M13.7654 1.93718L14.4719 0.524321L14.4719 0.524296C14.5719 0.324325 14.817 0.242888 15.0175 0.342598C15.2182 0.443083 15.2995 0.687078 15.1991 0.887933C15.1991 0.887939 15.1991 0.887945 15.1991 0.887951L14.4928 2.30052C14.4928 2.30056 14.4927 2.30061 14.4927 2.30065C14.3926 2.50017 14.1503 2.58325 13.9468 2.48244C13.7462 2.38184 13.665 2.13796 13.7654 1.9372C13.7654 1.93719 13.7654 1.93718 13.7654 1.93718Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
<path d="M6.35809 4.53828H7.77099C7.99578 4.53828 8.17746 4.71994 8.17746 4.94475C8.17746 5.16957 7.99578 5.35123 7.77099 5.35123H6.35809C6.13327 5.35123 5.95161 5.16955 5.95161 4.94475C5.95161 4.71994 6.13329 4.53828 6.35809 4.53828Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
<path d="M0.706474 11.6496H3.53232C3.7568 11.6496 3.93875 11.8316 3.93875 12.056V23.3593C3.93875 23.5838 3.75674 23.7658 3.53227 23.7658H0.706474C0.481989 23.7658 0.3 23.5837 0.3 23.3593V12.0561C0.3 11.8316 0.48201 11.6496 0.706474 11.6496Z" fill="white" stroke="#444D4F" stroke-width="0.6"/>
<path d="M13.1587 11.2039L12.9095 11.6502H13.4206H18.9284C19.9055 11.6502 20.777 12.3376 20.8815 13.2567L21.1796 13.2228L20.8816 13.2567C20.944 13.8055 20.7612 14.3116 20.4293 14.6824L20.2502 14.8825L20.4293 15.0826C21.3316 16.0904 20.8531 17.6965 19.5529 18.0508L19.2294 18.1389L19.3529 18.4507C19.4352 18.6587 19.4808 18.8848 19.4808 19.1212C19.4808 19.9578 18.9119 20.6664 18.14 20.8766L17.8165 20.9648L17.94 21.2765C18.4373 22.5326 17.4527 23.7664 16.1003 23.7664H11.479C9.06928 23.7664 7.42623 23.2102 6.09776 22.7606C6.04866 22.744 5.99999 22.7275 5.95173 22.7112V12.5126C5.9894 12.494 6.0268 12.4755 6.06397 12.4572C6.83712 12.076 7.50785 11.7453 8.26682 10.9863L8.26682 10.9863C8.92244 10.3306 9.39841 9.23207 9.71112 8.12757C10.0265 7.01379 10.1905 5.84056 10.1905 4.99212C10.1905 4.76731 10.3722 4.58564 10.597 4.58564H11.1799C12.6223 4.58564 13.8123 5.74165 13.8288 7.17657C13.8342 7.6407 13.776 8.4591 13.6576 9.267C13.5986 9.66975 13.5254 10.0645 13.4394 10.4071C13.3521 10.7546 13.2562 11.0293 13.1587 11.2039Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
</svg>
`,
		`<svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.6614 4.53828H16.2485C16.0237 4.53828 15.842 4.71994 15.842 4.94475C15.842 5.16955 16.0237 5.35123 16.2485 5.35123H17.6614C17.8861 5.35123 18.0678 5.16959 18.0678 4.94479L17.6614 4.53828ZM17.6614 4.53828C17.8861 4.53828 18.0678 4.7199 18.0678 4.94475L17.6614 4.53828Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
<path d="M8.82035 0.887941L8.82035 0.88793C8.71995 0.687164 8.80114 0.443275 9.00172 0.342717C9.20321 0.243059 9.44631 0.323736 9.54784 0.524782C9.54789 0.524896 9.54795 0.525009 9.54801 0.525123L10.254 1.93719C10.254 1.93719 10.254 1.93719 10.254 1.9372C10.3544 2.13802 10.2731 2.38197 10.0726 2.48248C9.86957 2.58307 9.62713 2.50073 9.52677 2.30072C9.52674 2.30067 9.52672 2.30062 9.52669 2.30057L8.82035 0.887941Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
<path d="M13.7654 1.93718L14.4719 0.524321L14.4719 0.524296C14.5719 0.324325 14.817 0.242888 15.0175 0.342598C15.2182 0.443083 15.2995 0.687078 15.1991 0.887933C15.1991 0.887939 15.1991 0.887945 15.1991 0.887951L14.4928 2.30052C14.4928 2.30056 14.4927 2.30061 14.4927 2.30065C14.3926 2.50017 14.1503 2.58325 13.9468 2.48244C13.7462 2.38184 13.665 2.13796 13.7654 1.9372C13.7654 1.93719 13.7654 1.93718 13.7654 1.93718Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
<path d="M6.35809 4.53828H7.77099C7.99578 4.53828 8.17746 4.71994 8.17746 4.94475C8.17746 5.16957 7.99578 5.35123 7.77099 5.35123H6.35809C6.13327 5.35123 5.95161 5.16955 5.95161 4.94475C5.95161 4.71994 6.13329 4.53828 6.35809 4.53828Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
<path d="M0.706474 11.6496H3.53232C3.7568 11.6496 3.93875 11.8316 3.93875 12.056V23.3593C3.93875 23.5838 3.75674 23.7658 3.53227 23.7658H0.706474C0.481989 23.7658 0.3 23.5837 0.3 23.3593V12.0561C0.3 11.8316 0.48201 11.6496 0.706474 11.6496Z" fill="white" stroke="#444D4F" stroke-width="0.6"/>
<path d="M13.1587 11.2039L12.9095 11.6502H13.4206H18.9284C19.9055 11.6502 20.777 12.3376 20.8815 13.2567L21.1796 13.2228L20.8816 13.2567C20.944 13.8055 20.7612 14.3116 20.4293 14.6824L20.2502 14.8825L20.4293 15.0826C21.3316 16.0904 20.8531 17.6965 19.5529 18.0508L19.2294 18.1389L19.3529 18.4507C19.4352 18.6587 19.4808 18.8848 19.4808 19.1212C19.4808 19.9578 18.9119 20.6664 18.14 20.8766L17.8165 20.9648L17.94 21.2765C18.4373 22.5326 17.4527 23.7664 16.1003 23.7664H11.479C9.06928 23.7664 7.42623 23.2102 6.09776 22.7606C6.04866 22.744 5.99999 22.7275 5.95173 22.7112V12.5126C5.9894 12.494 6.0268 12.4755 6.06397 12.4572C6.83712 12.076 7.50785 11.7453 8.26682 10.9863L8.26682 10.9863C8.92244 10.3306 9.39841 9.23207 9.71112 8.12757C10.0265 7.01379 10.1905 5.84056 10.1905 4.99212C10.1905 4.76731 10.3722 4.58564 10.597 4.58564H11.1799C12.6223 4.58564 13.8123 5.74165 13.8288 7.17657C13.8342 7.6407 13.776 8.4591 13.6576 9.267C13.5986 9.66975 13.5254 10.0645 13.4394 10.4071C13.3521 10.7546 13.2562 11.0293 13.1587 11.2039Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
</svg>
`,
		`<svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.6614 4.53828H16.2485C16.0237 4.53828 15.842 4.71994 15.842 4.94475C15.842 5.16955 16.0237 5.35123 16.2485 5.35123H17.6614C17.8861 5.35123 18.0678 5.16959 18.0678 4.94479L17.6614 4.53828ZM17.6614 4.53828C17.8861 4.53828 18.0678 4.7199 18.0678 4.94475L17.6614 4.53828Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
<path d="M8.82035 0.887941L8.82035 0.88793C8.71995 0.687164 8.80114 0.443275 9.00172 0.342717C9.20321 0.243059 9.44631 0.323736 9.54784 0.524782C9.54789 0.524896 9.54795 0.525009 9.54801 0.525123L10.254 1.93719C10.254 1.93719 10.254 1.93719 10.254 1.9372C10.3544 2.13802 10.2731 2.38197 10.0726 2.48248C9.86957 2.58307 9.62713 2.50073 9.52677 2.30072C9.52674 2.30067 9.52672 2.30062 9.52669 2.30057L8.82035 0.887941Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
<path d="M13.7654 1.93718L14.4719 0.524321L14.4719 0.524296C14.5719 0.324325 14.817 0.242888 15.0175 0.342598C15.2182 0.443083 15.2995 0.687078 15.1991 0.887933C15.1991 0.887939 15.1991 0.887945 15.1991 0.887951L14.4928 2.30052C14.4928 2.30056 14.4927 2.30061 14.4927 2.30065C14.3926 2.50017 14.1503 2.58325 13.9468 2.48244C13.7462 2.38184 13.665 2.13796 13.7654 1.9372C13.7654 1.93719 13.7654 1.93718 13.7654 1.93718Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
<path d="M6.35809 4.53828H7.77099C7.99578 4.53828 8.17746 4.71994 8.17746 4.94475C8.17746 5.16957 7.99578 5.35123 7.77099 5.35123H6.35809C6.13327 5.35123 5.95161 5.16955 5.95161 4.94475C5.95161 4.71994 6.13329 4.53828 6.35809 4.53828Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
<path d="M0.706474 11.6496H3.53232C3.7568 11.6496 3.93875 11.8316 3.93875 12.056V23.3593C3.93875 23.5838 3.75674 23.7658 3.53227 23.7658H0.706474C0.481989 23.7658 0.3 23.5837 0.3 23.3593V12.0561C0.3 11.8316 0.48201 11.6496 0.706474 11.6496Z" fill="white" stroke="#444D4F" stroke-width="0.6"/>
<path d="M13.1587 11.2039L12.9095 11.6502H13.4206H18.9284C19.9055 11.6502 20.777 12.3376 20.8815 13.2567L21.1796 13.2228L20.8816 13.2567C20.944 13.8055 20.7612 14.3116 20.4293 14.6824L20.2502 14.8825L20.4293 15.0826C21.3316 16.0904 20.8531 17.6965 19.5529 18.0508L19.2294 18.1389L19.3529 18.4507C19.4352 18.6587 19.4808 18.8848 19.4808 19.1212C19.4808 19.9578 18.9119 20.6664 18.14 20.8766L17.8165 20.9648L17.94 21.2765C18.4373 22.5326 17.4527 23.7664 16.1003 23.7664H11.479C9.06928 23.7664 7.42623 23.2102 6.09776 22.7606C6.04866 22.744 5.99999 22.7275 5.95173 22.7112V12.5126C5.9894 12.494 6.0268 12.4755 6.06397 12.4572C6.83712 12.076 7.50785 11.7453 8.26682 10.9863L8.26682 10.9863C8.92244 10.3306 9.39841 9.23207 9.71112 8.12757C10.0265 7.01379 10.1905 5.84056 10.1905 4.99212C10.1905 4.76731 10.3722 4.58564 10.597 4.58564H11.1799C12.6223 4.58564 13.8123 5.74165 13.8288 7.17657C13.8342 7.6407 13.776 8.4591 13.6576 9.267C13.5986 9.66975 13.5254 10.0645 13.4394 10.4071C13.3521 10.7546 13.2562 11.0293 13.1587 11.2039Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
</svg>
`,
		`<svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.6614 4.53828H16.2485C16.0237 4.53828 15.842 4.71994 15.842 4.94475C15.842 5.16955 16.0237 5.35123 16.2485 5.35123H17.6614C17.8861 5.35123 18.0678 5.16959 18.0678 4.94479L17.6614 4.53828ZM17.6614 4.53828C17.8861 4.53828 18.0678 4.7199 18.0678 4.94475L17.6614 4.53828Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
<path d="M8.82035 0.887941L8.82035 0.88793C8.71995 0.687164 8.80114 0.443275 9.00172 0.342717C9.20321 0.243059 9.44631 0.323736 9.54784 0.524782C9.54789 0.524896 9.54795 0.525009 9.54801 0.525123L10.254 1.93719C10.254 1.93719 10.254 1.93719 10.254 1.9372C10.3544 2.13802 10.2731 2.38197 10.0726 2.48248C9.86957 2.58307 9.62713 2.50073 9.52677 2.30072C9.52674 2.30067 9.52672 2.30062 9.52669 2.30057L8.82035 0.887941Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
<path d="M13.7654 1.93718L14.4719 0.524321L14.4719 0.524296C14.5719 0.324325 14.817 0.242888 15.0175 0.342598C15.2182 0.443083 15.2995 0.687078 15.1991 0.887933C15.1991 0.887939 15.1991 0.887945 15.1991 0.887951L14.4928 2.30052C14.4928 2.30056 14.4927 2.30061 14.4927 2.30065C14.3926 2.50017 14.1503 2.58325 13.9468 2.48244C13.7462 2.38184 13.665 2.13796 13.7654 1.9372C13.7654 1.93719 13.7654 1.93718 13.7654 1.93718Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
<path d="M6.35809 4.53828H7.77099C7.99578 4.53828 8.17746 4.71994 8.17746 4.94475C8.17746 5.16957 7.99578 5.35123 7.77099 5.35123H6.35809C6.13327 5.35123 5.95161 5.16955 5.95161 4.94475C5.95161 4.71994 6.13329 4.53828 6.35809 4.53828Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
<path d="M0.706474 11.6496H3.53232C3.7568 11.6496 3.93875 11.8316 3.93875 12.056V23.3593C3.93875 23.5838 3.75674 23.7658 3.53227 23.7658H0.706474C0.481989 23.7658 0.3 23.5837 0.3 23.3593V12.0561C0.3 11.8316 0.48201 11.6496 0.706474 11.6496Z" fill="white" stroke="#444D4F" stroke-width="0.6"/>
<path d="M13.1587 11.2039L12.9095 11.6502H13.4206H18.9284C19.9055 11.6502 20.777 12.3376 20.8815 13.2567L21.1796 13.2228L20.8816 13.2567C20.944 13.8055 20.7612 14.3116 20.4293 14.6824L20.2502 14.8825L20.4293 15.0826C21.3316 16.0904 20.8531 17.6965 19.5529 18.0508L19.2294 18.1389L19.3529 18.4507C19.4352 18.6587 19.4808 18.8848 19.4808 19.1212C19.4808 19.9578 18.9119 20.6664 18.14 20.8766L17.8165 20.9648L17.94 21.2765C18.4373 22.5326 17.4527 23.7664 16.1003 23.7664H11.479C9.06928 23.7664 7.42623 23.2102 6.09776 22.7606C6.04866 22.744 5.99999 22.7275 5.95173 22.7112V12.5126C5.9894 12.494 6.0268 12.4755 6.06397 12.4572C6.83712 12.076 7.50785 11.7453 8.26682 10.9863L8.26682 10.9863C8.92244 10.3306 9.39841 9.23207 9.71112 8.12757C10.0265 7.01379 10.1905 5.84056 10.1905 4.99212C10.1905 4.76731 10.3722 4.58564 10.597 4.58564H11.1799C12.6223 4.58564 13.8123 5.74165 13.8288 7.17657C13.8342 7.6407 13.776 8.4591 13.6576 9.267C13.5986 9.66975 13.5254 10.0645 13.4394 10.4071C13.3521 10.7546 13.2562 11.0293 13.1587 11.2039Z" fill="#D19A3A" stroke="#444D4F" stroke-width="0.6"/>
</svg>
`,
	]

	const svgs2 = [
		`<svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.6614 4.53828H16.2485C16.0237 4.53828 15.842 4.71994 15.842 4.94475C15.842 5.16955 16.0237 5.35123 16.2485 5.35123H17.6614C17.8861 5.35123 18.0678 5.16959 18.0678 4.94479L17.6614 4.53828ZM17.6614 4.53828C17.8861 4.53828 18.0678 4.7199 18.0678 4.94475L17.6614 4.53828Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    <path d="M8.82035 0.887941L8.82035 0.88793C8.71995 0.687164 8.80114 0.443275 9.00172 0.342717C9.20321 0.243059 9.44631 0.323736 9.54784 0.524782C9.54789 0.524896 9.54795 0.525009 9.54801 0.525123L10.254 1.93719C10.254 1.93719 10.254 1.93719 10.254 1.9372C10.3544 2.13802 10.2731 2.38197 10.0726 2.48248C9.86957 2.58307 9.62713 2.50073 9.52677 2.30072C9.52674 2.30067 9.52672 2.30062 9.52669 2.30057L8.82035 0.887941Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    <path d="M13.7654 1.93718L14.4719 0.524321L14.4719 0.524296C14.5719 0.324325 14.817 0.242888 15.0175 0.342598C15.2182 0.443083 15.2995 0.687078 15.1991 0.887933C15.1991 0.887939 15.1991 0.887945 15.1991 0.887951L14.4928 2.30052C14.4928 2.30056 14.4927 2.30061 14.4927 2.30065C14.3926 2.50017 14.1503 2.58325 13.9468 2.48244C13.7462 2.38184 13.665 2.13796 13.7654 1.9372C13.7654 1.93719 13.7654 1.93718 13.7654 1.93718Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    <path d="M6.35809 4.53828H7.77099C7.99578 4.53828 8.17746 4.71994 8.17746 4.94475C8.17746 5.16957 7.99578 5.35123 7.77099 5.35123H6.35809C6.13327 5.35123 5.95161 5.16955 5.95161 4.94475C5.95161 4.71994 6.13329 4.53828 6.35809 4.53828Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    <path d="M0.706474 11.6496H3.53232C3.7568 11.6496 3.93875 11.8316 3.93875 12.056V23.3593C3.93875 23.5838 3.75674 23.7658 3.53227 23.7658H0.706474C0.481989 23.7658 0.3 23.5837 0.3 23.3593V12.0561C0.3 11.8316 0.48201 11.6496 0.706474 11.6496Z" fill="white" stroke="white" stroke-width="0.6"/>
    <path d="M13.1587 11.2039L12.9095 11.6502H13.4206H18.9284C19.9055 11.6502 20.777 12.3376 20.8815 13.2567L21.1796 13.2228L20.8816 13.2567C20.944 13.8055 20.7612 14.3116 20.4293 14.6824L20.2502 14.8825L20.4293 15.0826C21.3316 16.0904 20.8531 17.6965 19.5529 18.0508L19.2294 18.1389L19.3529 18.4507C19.4352 18.6587 19.4808 18.8848 19.4808 19.1212C19.4808 19.9578 18.9119 20.6664 18.14 20.8766L17.8165 20.9648L17.94 21.2765C18.4373 22.5326 17.4527 23.7664 16.1003 23.7664H11.479C9.06928 23.7664 7.42623 23.2102 6.09776 22.7606C6.04866 22.744 5.99999 22.7275 5.95173 22.7112V12.5126C5.9894 12.494 6.0268 12.4755 6.06397 12.4572C6.83712 12.076 7.50785 11.7453 8.26682 10.9863L8.26682 10.9863C8.92244 10.3306 9.39841 9.23207 9.71112 8.12757C10.0265 7.01379 10.1905 5.84056 10.1905 4.99212C10.1905 4.76731 10.3722 4.58564 10.597 4.58564H11.1799C12.6223 4.58564 13.8123 5.74165 13.8288 7.17657C13.8342 7.6407 13.776 8.4591 13.6576 9.267C13.5986 9.66975 13.5254 10.0645 13.4394 10.4071C13.3521 10.7546 13.2562 11.0293 13.1587 11.2039Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    </svg>`,
		`<svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.6614 4.53828H16.2485C16.0237 4.53828 15.842 4.71994 15.842 4.94475C15.842 5.16955 16.0237 5.35123 16.2485 5.35123H17.6614C17.8861 5.35123 18.0678 5.16959 18.0678 4.94479L17.6614 4.53828ZM17.6614 4.53828C17.8861 4.53828 18.0678 4.7199 18.0678 4.94475L17.6614 4.53828Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    <path d="M8.82035 0.887941L8.82035 0.88793C8.71995 0.687164 8.80114 0.443275 9.00172 0.342717C9.20321 0.243059 9.44631 0.323736 9.54784 0.524782C9.54789 0.524896 9.54795 0.525009 9.54801 0.525123L10.254 1.93719C10.254 1.93719 10.254 1.93719 10.254 1.9372C10.3544 2.13802 10.2731 2.38197 10.0726 2.48248C9.86957 2.58307 9.62713 2.50073 9.52677 2.30072C9.52674 2.30067 9.52672 2.30062 9.52669 2.30057L8.82035 0.887941Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    <path d="M13.7654 1.93718L14.4719 0.524321L14.4719 0.524296C14.5719 0.324325 14.817 0.242888 15.0175 0.342598C15.2182 0.443083 15.2995 0.687078 15.1991 0.887933C15.1991 0.887939 15.1991 0.887945 15.1991 0.887951L14.4928 2.30052C14.4928 2.30056 14.4927 2.30061 14.4927 2.30065C14.3926 2.50017 14.1503 2.58325 13.9468 2.48244C13.7462 2.38184 13.665 2.13796 13.7654 1.9372C13.7654 1.93719 13.7654 1.93718 13.7654 1.93718Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    <path d="M6.35809 4.53828H7.77099C7.99578 4.53828 8.17746 4.71994 8.17746 4.94475C8.17746 5.16957 7.99578 5.35123 7.77099 5.35123H6.35809C6.13327 5.35123 5.95161 5.16955 5.95161 4.94475C5.95161 4.71994 6.13329 4.53828 6.35809 4.53828Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    <path d="M0.706474 11.6496H3.53232C3.7568 11.6496 3.93875 11.8316 3.93875 12.056V23.3593C3.93875 23.5838 3.75674 23.7658 3.53227 23.7658H0.706474C0.481989 23.7658 0.3 23.5837 0.3 23.3593V12.0561C0.3 11.8316 0.48201 11.6496 0.706474 11.6496Z" fill="white" stroke="white" stroke-width="0.6"/>
    <path d="M13.1587 11.2039L12.9095 11.6502H13.4206H18.9284C19.9055 11.6502 20.777 12.3376 20.8815 13.2567L21.1796 13.2228L20.8816 13.2567C20.944 13.8055 20.7612 14.3116 20.4293 14.6824L20.2502 14.8825L20.4293 15.0826C21.3316 16.0904 20.8531 17.6965 19.5529 18.0508L19.2294 18.1389L19.3529 18.4507C19.4352 18.6587 19.4808 18.8848 19.4808 19.1212C19.4808 19.9578 18.9119 20.6664 18.14 20.8766L17.8165 20.9648L17.94 21.2765C18.4373 22.5326 17.4527 23.7664 16.1003 23.7664H11.479C9.06928 23.7664 7.42623 23.2102 6.09776 22.7606C6.04866 22.744 5.99999 22.7275 5.95173 22.7112V12.5126C5.9894 12.494 6.0268 12.4755 6.06397 12.4572C6.83712 12.076 7.50785 11.7453 8.26682 10.9863L8.26682 10.9863C8.92244 10.3306 9.39841 9.23207 9.71112 8.12757C10.0265 7.01379 10.1905 5.84056 10.1905 4.99212C10.1905 4.76731 10.3722 4.58564 10.597 4.58564H11.1799C12.6223 4.58564 13.8123 5.74165 13.8288 7.17657C13.8342 7.6407 13.776 8.4591 13.6576 9.267C13.5986 9.66975 13.5254 10.0645 13.4394 10.4071C13.3521 10.7546 13.2562 11.0293 13.1587 11.2039Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    </svg>`,
		`<svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.6614 4.53828H16.2485C16.0237 4.53828 15.842 4.71994 15.842 4.94475C15.842 5.16955 16.0237 5.35123 16.2485 5.35123H17.6614C17.8861 5.35123 18.0678 5.16959 18.0678 4.94479L17.6614 4.53828ZM17.6614 4.53828C17.8861 4.53828 18.0678 4.7199 18.0678 4.94475L17.6614 4.53828Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    <path d="M8.82035 0.887941L8.82035 0.88793C8.71995 0.687164 8.80114 0.443275 9.00172 0.342717C9.20321 0.243059 9.44631 0.323736 9.54784 0.524782C9.54789 0.524896 9.54795 0.525009 9.54801 0.525123L10.254 1.93719C10.254 1.93719 10.254 1.93719 10.254 1.9372C10.3544 2.13802 10.2731 2.38197 10.0726 2.48248C9.86957 2.58307 9.62713 2.50073 9.52677 2.30072C9.52674 2.30067 9.52672 2.30062 9.52669 2.30057L8.82035 0.887941Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    <path d="M13.7654 1.93718L14.4719 0.524321L14.4719 0.524296C14.5719 0.324325 14.817 0.242888 15.0175 0.342598C15.2182 0.443083 15.2995 0.687078 15.1991 0.887933C15.1991 0.887939 15.1991 0.887945 15.1991 0.887951L14.4928 2.30052C14.4928 2.30056 14.4927 2.30061 14.4927 2.30065C14.3926 2.50017 14.1503 2.58325 13.9468 2.48244C13.7462 2.38184 13.665 2.13796 13.7654 1.9372C13.7654 1.93719 13.7654 1.93718 13.7654 1.93718Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    <path d="M6.35809 4.53828H7.77099C7.99578 4.53828 8.17746 4.71994 8.17746 4.94475C8.17746 5.16957 7.99578 5.35123 7.77099 5.35123H6.35809C6.13327 5.35123 5.95161 5.16955 5.95161 4.94475C5.95161 4.71994 6.13329 4.53828 6.35809 4.53828Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    <path d="M0.706474 11.6496H3.53232C3.7568 11.6496 3.93875 11.8316 3.93875 12.056V23.3593C3.93875 23.5838 3.75674 23.7658 3.53227 23.7658H0.706474C0.481989 23.7658 0.3 23.5837 0.3 23.3593V12.0561C0.3 11.8316 0.48201 11.6496 0.706474 11.6496Z" fill="white" stroke="white" stroke-width="0.6"/>
    <path d="M13.1587 11.2039L12.9095 11.6502H13.4206H18.9284C19.9055 11.6502 20.777 12.3376 20.8815 13.2567L21.1796 13.2228L20.8816 13.2567C20.944 13.8055 20.7612 14.3116 20.4293 14.6824L20.2502 14.8825L20.4293 15.0826C21.3316 16.0904 20.8531 17.6965 19.5529 18.0508L19.2294 18.1389L19.3529 18.4507C19.4352 18.6587 19.4808 18.8848 19.4808 19.1212C19.4808 19.9578 18.9119 20.6664 18.14 20.8766L17.8165 20.9648L17.94 21.2765C18.4373 22.5326 17.4527 23.7664 16.1003 23.7664H11.479C9.06928 23.7664 7.42623 23.2102 6.09776 22.7606C6.04866 22.744 5.99999 22.7275 5.95173 22.7112V12.5126C5.9894 12.494 6.0268 12.4755 6.06397 12.4572C6.83712 12.076 7.50785 11.7453 8.26682 10.9863L8.26682 10.9863C8.92244 10.3306 9.39841 9.23207 9.71112 8.12757C10.0265 7.01379 10.1905 5.84056 10.1905 4.99212C10.1905 4.76731 10.3722 4.58564 10.597 4.58564H11.1799C12.6223 4.58564 13.8123 5.74165 13.8288 7.17657C13.8342 7.6407 13.776 8.4591 13.6576 9.267C13.5986 9.66975 13.5254 10.0645 13.4394 10.4071C13.3521 10.7546 13.2562 11.0293 13.1587 11.2039Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    </svg>`,
		`<svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.6614 4.53828H16.2485C16.0237 4.53828 15.842 4.71994 15.842 4.94475C15.842 5.16955 16.0237 5.35123 16.2485 5.35123H17.6614C17.8861 5.35123 18.0678 5.16959 18.0678 4.94479L17.6614 4.53828ZM17.6614 4.53828C17.8861 4.53828 18.0678 4.7199 18.0678 4.94475L17.6614 4.53828Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    <path d="M8.82035 0.887941L8.82035 0.88793C8.71995 0.687164 8.80114 0.443275 9.00172 0.342717C9.20321 0.243059 9.44631 0.323736 9.54784 0.524782C9.54789 0.524896 9.54795 0.525009 9.54801 0.525123L10.254 1.93719C10.254 1.93719 10.254 1.93719 10.254 1.9372C10.3544 2.13802 10.2731 2.38197 10.0726 2.48248C9.86957 2.58307 9.62713 2.50073 9.52677 2.30072C9.52674 2.30067 9.52672 2.30062 9.52669 2.30057L8.82035 0.887941Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    <path d="M13.7654 1.93718L14.4719 0.524321L14.4719 0.524296C14.5719 0.324325 14.817 0.242888 15.0175 0.342598C15.2182 0.443083 15.2995 0.687078 15.1991 0.887933C15.1991 0.887939 15.1991 0.887945 15.1991 0.887951L14.4928 2.30052C14.4928 2.30056 14.4927 2.30061 14.4927 2.30065C14.3926 2.50017 14.1503 2.58325 13.9468 2.48244C13.7462 2.38184 13.665 2.13796 13.7654 1.9372C13.7654 1.93719 13.7654 1.93718 13.7654 1.93718Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    <path d="M6.35809 4.53828H7.77099C7.99578 4.53828 8.17746 4.71994 8.17746 4.94475C8.17746 5.16957 7.99578 5.35123 7.77099 5.35123H6.35809C6.13327 5.35123 5.95161 5.16955 5.95161 4.94475C5.95161 4.71994 6.13329 4.53828 6.35809 4.53828Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    <path d="M0.706474 11.6496H3.53232C3.7568 11.6496 3.93875 11.8316 3.93875 12.056V23.3593C3.93875 23.5838 3.75674 23.7658 3.53227 23.7658H0.706474C0.481989 23.7658 0.3 23.5837 0.3 23.3593V12.0561C0.3 11.8316 0.48201 11.6496 0.706474 11.6496Z" fill="white" stroke="white" stroke-width="0.6"/>
    <path d="M13.1587 11.2039L12.9095 11.6502H13.4206H18.9284C19.9055 11.6502 20.777 12.3376 20.8815 13.2567L21.1796 13.2228L20.8816 13.2567C20.944 13.8055 20.7612 14.3116 20.4293 14.6824L20.2502 14.8825L20.4293 15.0826C21.3316 16.0904 20.8531 17.6965 19.5529 18.0508L19.2294 18.1389L19.3529 18.4507C19.4352 18.6587 19.4808 18.8848 19.4808 19.1212C19.4808 19.9578 18.9119 20.6664 18.14 20.8766L17.8165 20.9648L17.94 21.2765C18.4373 22.5326 17.4527 23.7664 16.1003 23.7664H11.479C9.06928 23.7664 7.42623 23.2102 6.09776 22.7606C6.04866 22.744 5.99999 22.7275 5.95173 22.7112V12.5126C5.9894 12.494 6.0268 12.4755 6.06397 12.4572C6.83712 12.076 7.50785 11.7453 8.26682 10.9863L8.26682 10.9863C8.92244 10.3306 9.39841 9.23207 9.71112 8.12757C10.0265 7.01379 10.1905 5.84056 10.1905 4.99212C10.1905 4.76731 10.3722 4.58564 10.597 4.58564H11.1799C12.6223 4.58564 13.8123 5.74165 13.8288 7.17657C13.8342 7.6407 13.776 8.4591 13.6576 9.267C13.5986 9.66975 13.5254 10.0645 13.4394 10.4071C13.3521 10.7546 13.2562 11.0293 13.1587 11.2039Z" fill="#D19A3A" stroke="white" stroke-width="0.6"/>
    </svg>`,
	]

	const svgs3 = [
		`<svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M21.5 0C33.3867 0 43 9.61329 43 21.5C43 33.3867 33.3867 43 21.5 43C9.61329 43 0 33.3867 0 21.5C0 9.61329 9.61329 0 21.5 0ZM8.70393 22.3444C9.48338 21.565 10.1979 20.8505 10.9773 20.071C13.1208 22.2145 15.3293 24.423 17.4728 26.5665C22.3444 21.6949 27.216 16.8233 32.0876 11.9517C32.8671 12.7311 33.5816 13.4456 34.361 14.2251C28.71 19.8761 23.1239 25.4622 17.4728 31.1133C14.5498 28.1903 11.6269 25.2674 8.70393 22.3444Z" fill="#06C168"/>
		</svg>`,
		`<svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M21.5 0C33.3867 0 43 9.61329 43 21.5C43 33.3867 33.3867 43 21.5 43C9.61329 43 0 33.3867 0 21.5C0 9.61329 9.61329 0 21.5 0ZM8.70393 22.3444C9.48338 21.565 10.1979 20.8505 10.9773 20.071C13.1208 22.2145 15.3293 24.423 17.4728 26.5665C22.3444 21.6949 27.216 16.8233 32.0876 11.9517C32.8671 12.7311 33.5816 13.4456 34.361 14.2251C28.71 19.8761 23.1239 25.4622 17.4728 31.1133C14.5498 28.1903 11.6269 25.2674 8.70393 22.3444Z" fill="#06C168"/>
		</svg>`,
		`<svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M21.5 0C33.3867 0 43 9.61329 43 21.5C43 33.3867 33.3867 43 21.5 43C9.61329 43 0 33.3867 0 21.5C0 9.61329 9.61329 0 21.5 0ZM8.70393 22.3444C9.48338 21.565 10.1979 20.8505 10.9773 20.071C13.1208 22.2145 15.3293 24.423 17.4728 26.5665C22.3444 21.6949 27.216 16.8233 32.0876 11.9517C32.8671 12.7311 33.5816 13.4456 34.361 14.2251C28.71 19.8761 23.1239 25.4622 17.4728 31.1133C14.5498 28.1903 11.6269 25.2674 8.70393 22.3444Z" fill="#06C168"/>
		</svg>`,
		`<svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M21.5 0C33.3867 0 43 9.61329 43 21.5C43 33.3867 33.3867 43 21.5 43C9.61329 43 0 33.3867 0 21.5C0 9.61329 9.61329 0 21.5 0ZM8.70393 22.3444C9.48338 21.565 10.1979 20.8505 10.9773 20.071C13.1208 22.2145 15.3293 24.423 17.4728 26.5665C22.3444 21.6949 27.216 16.8233 32.0876 11.9517C32.8671 12.7311 33.5816 13.4456 34.361 14.2251C28.71 19.8761 23.1239 25.4622 17.4728 31.1133C14.5498 28.1903 11.6269 25.2674 8.70393 22.3444Z" fill="#06C168"/>
		</svg>`,
	]

	var rolodexes = document.querySelectorAll('[data-rolodex]')

	if (rolodexes) {
		for (var i = 0; i < rolodexes.length; i++) {
			var sentences = rolodexes[i].dataset.rolodex.split('.')

			setup(rolodexes[i], sentences)
		}

		// Create the additional text hidden from screen
		function setup(rolodex, sentences) {
			sentences.forEach(function (sentence, i) {
				if (sentence !== '') {
					var newNode = document.createElement('p')

					if (rolodex.classList.contains('client-map')) {
						newNode.innerHTML += svgs3[i]
					} else if (rolodex.classList.contains('top-bar')) {
						newNode.innerHTML += svgs2[i]
					} else {
						newNode.innerHTML += svgs[i]
					}

					newNode.innerHTML += sentence.replace('+', '.')
					newNode.classList.add('next')
					rolodex.childNodes[
						rolodex.childNodes.length - 1
					].parentNode.insertBefore(
						newNode,
						rolodex.childNodes[rolodex.childNodes.length - 1]
							.nextSibling
					)
				}
			})

			rollText(rolodex)
		}

		// Roll the text
		function rollText(rolodex, time = 5000) {
			var count = 0

			var roloItems = rolodex.querySelectorAll('p')
			// set rolodex parent item height to height of item TODO: set it to height of largest item
			//  let height = roloItems[0].offsetHeight;
			// rolodex.style.height = height + "px";

			setInterval(function () {
				var last = roloItems[count]
				last.classList.add('last')
				last.classList.remove('next')

				if (count >= roloItems.length - 1) {
					count = 0
				} else {
					count++
				}
				var current = roloItems[count]
				current.classList.remove('next')
				current.classList.remove('last')

				var next
				if (count + 1 >= roloItems.length) {
					next = roloItems[0]
				} else {
					next = roloItems[count + 1]
				}
				next.classList.add('next')
				next.classList.remove('last')
			}, time)
		}
	}
}
