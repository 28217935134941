export default function calendar() {
  $(function () {
		$('#datepicker').datepicker({
			changeMonth: true,
			changeYear: true,
			altField: '#actualDate',
			minDate: new Date(),
			monthNamesShort: $.datepicker.regional['en'].monthNames,
		})
  })
}
